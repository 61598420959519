export const environment = {
  baseUrl: 'https://retention.staging-euw3.aodocs.app',
  apiUrl: '/_ah/api/',
  aodocsUrl: 'https://eu.aodocs.app/',
  clientId: '202473789874-i33kve2ro87ts59vfp2hsqplaegbcu5n',
  gcsAPI: 'https://storage.googleapis.com/upload/storage/v1/b/{BUCKET}/o',
  ossApi: 'https://storage.eu.aodocs.app/api/storage',
  version: '4.0.0',
  production: true
};
